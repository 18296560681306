/* If you need to add @import statements, do so up here */
@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600;700&display=swap');

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.overlay::after {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-image: linear-gradient(120deg, #fcaa36, #d2998e);
opacity: .7;
} 
.shape>* {
    -webkit-transform: scale(2);
    transform: scale(2);
}
.shape-top {
    -webkit-transform-origin: top center;
    transform-origin: top center;
}
.shape-flip-x {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.shape-flip-y {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}
.logo-gradient-stop-1 {
    stop-color: #3688fc;
}
.logo-gradient-stop-2 {
    stop-color: #60a5fa;
}